import { debounce } from "./utilities.js";

let searchTerm = '';
let autocompletePopup;
let ajaxRequest = null;

const renderMenu = () => {
	const htmlStr = `<ul tabindex="0" class="ui-autocomplete" style="display: none;" unselectable="on" />`;
	window.site.body.append(htmlStr)

	autocompletePopup = document.querySelector('.ui-autocomplete');
	autocompletePopup.addEventListener('mousemove', (e) => {
		const anchorElementNodeType = 'A';
		if (e.target.nodeName === anchorElementNodeType) {
			autocompletePopup.querySelector('.active')?.classList.remove('active')
			e.target.classList.add('active')
			const search = $('.js-freeform-search:visible'); // To change after ST-4422 
			search.val(autocompletePopup.querySelector('.ui-autocomplete__link.active').textContent);
		}
	})

	autocompletePopup.addEventListener('mouseout', (e) => {
		e.target.classList.remove('active')
		const search = $('.js-freeform-search:visible'); // To change after ST-4422 
		search.val(searchTerm);
	})

	window.site.doc.on('click', (e) => {
		if (!e.target.closest('.ui-autocomplete, .js-freeform-search')) {
			closeMenu()
		}
	})
}

const menuHeading = (title, category) => {
	return `<li class="ui-autocomplete__menu-item">
		<span class="ui-autocomplete__${category.toLowerCase()}">${title}</span>
	</li>`
}

const menuItem = (data, category) => {
	const { Name: name, Uri: uri } = data;

	return `<li class="ui-autocomplete__menu-item" aria-label="${category}: ${name}">
		<a class="ui-autocomplete__link" href="${uri}?s=${searchTerm}">${name}</a>
	</li>`
}

const buildMenuItems = (title, category, resultsData = []) => {
	const resultsHeadingHtml = menuHeading(title, category)
	autocompletePopup.innerHTML += resultsHeadingHtml;

	if (resultsData.Results) {
		const resultsHtml = resultsData.Results.map(data => menuItem(data, category))
		autocompletePopup.innerHTML += resultsHtml.join('')
	}

	if (resultsData.Remaining) {
		const remainingHtml = parseInt(resultsData.Remaining) > 0 ? menuHeading(`${resultsData.Remaining} more available`, 'more') : '';
		autocompletePopup.innerHTML += remainingHtml;
	}
}

const resetMenuItems = () => autocompletePopup.innerHTML = '';

const moveFocusTo = (direction) => {
	const links = Array.from(document.querySelectorAll('.ui-autocomplete__link'));
	const linksCount = links.length;
	const activeLink = autocompletePopup.querySelector('.ui-autocomplete__link.active');
	const activeIndex = links.indexOf(activeLink);
	const delta = (direction === 'next') ? + 1 : (direction === 'prev') ? - 1 : 0;

	let nextActiveIndex = activeIndex + delta;
	nextActiveIndex = nextActiveIndex < 0 ? linksCount - 1 : nextActiveIndex;
	nextActiveIndex = nextActiveIndex % linksCount;

	links.forEach(item => item.classList.remove('active'))
	links[nextActiveIndex].classList.add('active')

	const search = $('.js-freeform-search:visible'); // To change after ST-4422 
	search.val(autocompletePopup.querySelector('.ui-autocomplete__link.active').textContent); // To change after ST-4422 
}

const openMenu = () => {
	const search = $('.js-freeform-search:visible'); // To update after ST-4422
	const searchPos = search[0].getBoundingClientRect(); // To change after ST-4422 

	autocompletePopup.style.top = `${searchPos.top + searchPos.height + 1}px`;
	autocompletePopup.style.left = `${searchPos.left}px`;
	autocompletePopup.style.width = `${searchPos.width}px`;
	autocompletePopup.style.display = 'block';
}

const closeMenu = () => {
	autocompletePopup.style.top = `-400px`;
	autocompletePopup.style.left = `-400px`;
	autocompletePopup.style.display = 'none'
	$('.js-freeform-search:visible').focus(); // To change after ST-4422 
}

const handleSpecialKeyboard = (e) => {
	if (e.shiftKey && e.key === 'Tab') {
		e.preventDefault()
		moveFocusTo('prev')
	}
	else {
		switch (e.key) {
			case 'ArrowUp':
				e.preventDefault()
				moveFocusTo('prev')
				break;

			case 'Tab':
			case 'ArrowDown':
				e.preventDefault()
				moveFocusTo('next')
				break;

			case 'Escape':
				closeMenu()
				break;
		}
	}
}

const handleKeyboard = (e, form) => {
	const keyboardInput = e.target.value;

	ajaxRequest?.abort();
	ajaxRequest = null;

	if (keyboardInput.length > 2) {
		ajaxRequest = $.ajax({
			url: '/api/freeform-search',
			method: 'POST',
			data: $(form).serialize(),
			success: function(returned) {
				try {
					returned = JSON.parse(returned);
				}
				catch {
					console.log('Freeform Search ajax response failed to JSON.parse')
				}

				searchTerm = keyboardInput;
				resetMenuItems()

				if ((!returned.Properties.Results.length && !returned.Properties.Results.length && !returned.Properties.Results.length) || !returned.Success) {
					buildMenuItems('No matches found', 'empty')
				}
				else {
					returned.Properties.Results.length && buildMenuItems('Properties', 'category', returned.Properties)
					returned.Regions.Results.length && buildMenuItems('Regions', 'category', returned.Regions)
					returned.Countries.Results.length && buildMenuItems('Countries', 'category', returned.Countries)
				}

				openMenu()
			},
			error: function(e) {
				console.log('Ajax call to freeform-search failed', e)
			}
		})
	}
}

const init = () => {
	renderMenu()
	const inputs = document.querySelectorAll('.js-freeform-search'); // Can be changed to document.querySelector() when ST-4422 is merged

	inputs.forEach((input) => {
		const form = input.closest('form')

		input.addEventListener('input', (e) => {
			debounce(handleKeyboard(e, form))
		})

		form.addEventListener('submit', (e) => {
			e.preventDefault()
			const activeOrFirstLink = autocompletePopup.querySelector('.ui-autocomplete__link.active') ?? autocompletePopup.querySelector('.ui-autocomplete__link')
			activeOrFirstLink.classList.add('active')// .active may already exist but its less effort to add it again than do a prescence check first
			activeOrFirstLink.click();
		})

		input.addEventListener('keydown', handleSpecialKeyboard)
		input.classList.add('js-autocomplete-done');
	})

	window.site.win.on('resize', debounce(closeMenu))
}

export default init;
